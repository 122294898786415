<template>
  <div>
    <div class="user-title">
      <h2>账户安全</h2>
    </div>
    <div class="user-safe-list">
      <div class="item">
        <div class="item-icon"><i class="iconfont icon-password-alt"></i></div>
        <div class="item-info">
          <div class="info-name">登录密码</div>
          <p><span class="ftx-01">互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。</span></p>
        </div>
        <div class="item-handle">
          <router-link :to="{path:'/changePassword'}" class="handle-btn">修改</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSafe"
}
</script>

<style scoped>

</style>
